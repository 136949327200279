import { BigNumber } from 'ethers'
import { TokenPrices } from '../MarketHeader'
import { CHART_PERIODS } from './lib/legacy'
import { Bar } from './types'
import { TIMEZONE_OFFSET } from 'domain/prices'

export function formatTimeInBarToMs(bar: Bar) {
  return {
    ...bar,
    time: bar.time * 1000,
  }
}

export function getCurrentCandleTime(period: string) {
  // Converts current time to seconds, rounds down to nearest period, adds timezone offset, and converts back to milliseconds
  const periodSeconds = CHART_PERIODS[period]
  return (
    Math.floor(Date.now() / 1000 / periodSeconds) * periodSeconds +
    TIMEZONE_OFFSET
  )
}

export function getMax(...values: (number | undefined)[]): number {
  return Math.max(
    ...(values.filter(
      (value) => Boolean(value) && typeof value === 'number',
    ) as number[]),
  )
}

export function getMin(...values: (number | undefined)[]): number {
  return Math.min(
    ...(values.filter(
      (value) => Boolean(value) && typeof value === 'number',
    ) as number[]),
  )
}

export function getNextBarTime(barTime: number, type: string) {
  const date = new Date(barTime)

  switch (type) {
    case '1':
    case '5':
    case '15':
    case '30':
    case '60':
    case '240':
      date.setMinutes(date.getMinutes() + parseInt(type))
      break
    case '1D':
      date.setDate(date.getDate() + 1)
      break
    case '1W':
      date.setDate(date.getDate() + 7)
      break
    case '1M':
      date.setMonth(date.getMonth() + 1)
      break
  }

  return date.getTime()
}

export function getMidPrice(prices: Partial<TokenPrices>) {
  const min = prices.minPrice || BigNumber.from('0')
  const max = prices.maxPrice || BigNumber.from('0')
  return min.add(max).div(2)
}
