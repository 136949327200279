import EmptyState from '@components/shared/EmptyState'
import FavoriteMarketButton from '@components/shared/FavoriteMarketButton'
import MarketChange from '@components/shared/MarketChange'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import { SortableColumnHeader } from '@components/shared/TableElements'
import TableRowAccordian from '@components/TableRowAccordian'
import LiveMarketPrice from '@components/trade/gmx/LiveMarketPrice'
import { formatAmount } from '@components/trade/gmx/MarketHeader'
import { formatUsd } from '@components/trade/gmx/tradingview/lib/numbers'
import { getFundingFactorPerPeriod } from 'domain/synthetics/fees'
import { getAvailableUsdLiquidityForPosition } from 'domain/synthetics/markets'
import { use24hPriceDelta } from 'domain/synthetics/tokens/use24PriceDelta'
import { useChainId } from 'gmx/lib/chains'
import { CHART_PERIODS } from 'gmx/lib/legacy'
import { IndexTokenStats } from 'hooks/gmx/useIndexTokenStats'
import useLocalStorageState from 'hooks/useLocalStorageState'
import { useSortableData } from 'hooks/useSortableData'
import { useViewport } from 'hooks/useViewport'
import { keyBy, uniqBy } from 'lodash'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { DEFAULT_FAVORITE_MKTS, FAVORITE_MARKET_KEY } from 'utils/constants'
import { Tab } from './MarketsPage'
import {
  MarketVolumeInfo,
  useMarketInfoVolume,
} from 'hooks/useMarketInfoVolume'
import { BigNumber, ethers } from 'ethers'
import { numberCompacter } from 'utils/numbers'

interface Props {
  data: IndexTokenStats[]
  tab: Tab
}

export const getVolumeInfo = (
  address: string,
  keyBymarketsVolumeInfos: Record<string, MarketVolumeInfo>,
) => {
  return numberCompacter.format(
    Number(
      ethers.utils.formatUnits(
        BigNumber.from(keyBymarketsVolumeInfos[address]?.volumeUsd || 0),
        30,
      ),
    ),
  )
}
const MarketsTable = ({ data, tab }: Props) => {
  const { chainId } = useChainId()
  const { isMobile } = useViewport()

  const [favoriteMarkets] = useLocalStorageState<string[]>(
    FAVORITE_MARKET_KEY,
    DEFAULT_FAVORITE_MKTS,
  )

  const { priceDeltas } = use24hPriceDelta(chainId, 'ETH')
  const keyByPriceDeltas = keyBy(priceDeltas, 'tokenSymbol')
  const { items: marketsData, requestSort, sortConfig } = useSortableData(data)
  const { push } = useRouter()

  const { marketsVolumeInfos } = useMarketInfoVolume(chainId)
  const keyBymarketsVolumeInfos = keyBy(marketsVolumeInfos, (marketInfo) =>
    marketInfo.marketToken.toLowerCase(),
  )

  const markets = useMemo(() => {
    return tab === Tab.Favourites
      ? marketsData.filter((market) =>
          favoriteMarkets.includes(market.token.symbol),
        )
      : marketsData
  }, [marketsData, tab, favoriteMarkets])

  if (isMobile) {
    return (
      <>
        {markets.length === 0 ? (
          <div className="flex h-[75vh]">
            <EmptyState
              text="No Market Available"
              textClasses="text-th-fgd-2"
            />
          </div>
        ) : (
          <div>
            {uniqBy(markets, 'token.name').map((market, idx) => {
              const fundingRateLong = getFundingFactorPerPeriod(
                market.marketsStats[0].marketInfo,
                true,
                CHART_PERIODS['1h'],
              ).mul(100)

              const fundingRateShort = getFundingFactorPerPeriod(
                market.marketsStats[0].marketInfo,
                false,
                CHART_PERIODS['1h'],
              ).mul(100)
              return (
                <TableRowAccordian
                  key={`${market.token.symbol}-${idx}`}
                  VisibleContent={
                    <div className="py-4 pr-4">
                      <div className="flex h-10 justify-between">
                        <div className="flex flex-1 flex-col justify-between">
                          <span className="text-xxs font-normal text-th-fgd-2">
                            MARKET
                          </span>
                          <div className="flex items-center gap-2">
                            <RenderTokenIcon symbol={market.token.symbol} />
                            <span className="text-sm font-semibold text-th-fgd-1">
                              {market.token.symbol}
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col items-end justify-between">
                          <span className="text-xxs font-normal text-th-fgd-2">
                            PRICE
                          </span>
                          <span className="text-sm font-medium text-th-fgd-1 opacity-90">
                            <LiveMarketPrice
                              quotePythId={market.token.pythId || ''}
                              basePythId={market.token.baseId}
                              tokenAddress={market.token.address}
                              textContentOnly={true}
                            />
                          </span>
                        </div>
                        <div className="flex flex-1 flex-col items-end justify-between">
                          <span className="text-xxs font-normal text-th-fgd-2">
                            24H CHANGE
                          </span>
                          <span className="text-sm font-medium opacity-90">
                            <MarketChange
                              deltaPercentage={
                                keyByPriceDeltas[market.token.symbol]
                                  .deltaPercentage
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                  HiddenContent={
                    <div className="relative flex pb-4 pr-4">
                      <div className="flex flex-1 flex-col gap-2">
                        <span className="text-xxs font-normal text-th-fgd-2">
                          FUNDING RATE
                        </span>
                        <div className="flex items-center gap-1">
                          <span className="text-th-success">
                            {formatAmount(fundingRateLong.abs(), 30, 2)}%
                          </span>
                          <span className="text-th-error">
                            {formatAmount(fundingRateShort.abs(), 30, 2)}%
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col items-end gap-4">
                        <div className="flex flex-1 flex-col items-end gap-2">
                          <span className="text-xxs font-normal text-th-fgd-2">
                            AVAILABLE LIQUIDITY
                          </span>
                          <div className="flex items-center gap-1">
                            <span className="text-th-success">
                              {formatUsd(
                                getAvailableUsdLiquidityForPosition(
                                  market.marketsStats[0].marketInfo,
                                  true,
                                ),
                              )}
                            </span>
                            <span className="flex-1 text-start text-th-error">
                              {formatUsd(
                                getAvailableUsdLiquidityForPosition(
                                  market.marketsStats[0].marketInfo,
                                  false,
                                ),
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col items-end gap-2">
                          <span className="text-xxs font-normal text-th-fgd-2">
                            24H TRADES
                          </span>
                          <span className="text-sm font-medium text-th-fgd-1 opacity-90">
                            {'-'}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col items-end gap-4">
                        <div className="flex flex-1 flex-col items-end gap-2 pr-4">
                          <span className="text-xxs font-normal text-th-fgd-2">
                            24H VOLUME
                          </span>
                          <span className="text-sm font-medium text-th-fgd-1 opacity-90">
                            {'-'}
                          </span>
                        </div>
                        <div className="flex flex-1 flex-col items-end gap-2">
                          <span className="text-xxs font-normal text-th-fgd-2">
                            OPEN INTEREST
                          </span>
                          <div className="flex items-center gap-1">
                            <span className="text-th-success">
                              {market &&
                                formatUsd(
                                  market.marketsStats[0].marketInfo
                                    .longInterestUsd,
                                  {
                                    displayDecimals: 0,
                                  },
                                )}
                            </span>
                            <span className="text-th-error">
                              {market &&
                                formatUsd(
                                  market.marketsStats[0].marketInfo
                                    .shortInterestUsd,
                                  {
                                    displayDecimals: 0,
                                  },
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="absolute right-0 top-3 h-4 w-4"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <FavoriteMarketButton
                          market={market.token.symbol}
                          localStorageKey={FAVORITE_MARKET_KEY}
                          DefaultLocalStorageValue={DEFAULT_FAVORITE_MKTS}
                        />
                      </div>
                    </div>
                  }
                  handleOnClick={() =>
                    push({
                      pathname: '/trade',
                      query: {
                        symbol: market.token.symbol,
                      },
                    })
                  }
                />
              )
            })}
          </div>
        )}
      </>
    )
  }

  return (
    <div className="h-full">
      <table className="w-full flex-1 border-collapse text-left">
        <thead className="table w-full table-fixed">
          <tr className="h-10 border-b border-th-input-border">
            <th className="pl-6">
              <SortableColumnHeader
                sortKey="market"
                sort={() => requestSort('token.symbol')}
                sortConfig={sortConfig}
                title={'MARKET'}
                titleClass="font-normal"
              />
            </th>
            <th align="left">
              <SortableColumnHeader
                sortKey="market"
                sort={() => requestSort('market')}
                sortConfig={sortConfig}
                title={'PRICE'}
                titleClass="font-normal"
              />
            </th>
            <th align="left">
              <SortableColumnHeader
                sortKey="market"
                sort={() => requestSort('market')}
                sortConfig={sortConfig}
                title={'24H CHANGE'}
                titleClass="font-normal"
              />
            </th>
            <th align="left">
              <SortableColumnHeader
                sortKey="market"
                sort={() => requestSort('market')}
                sortConfig={sortConfig}
                title={'Volume'}
                titleClass="font-normal"
              />
            </th>
            <th align="left">
              <SortableColumnHeader
                sortKey="market"
                sort={() => requestSort('market')}
                sortConfig={sortConfig}
                title={'24h trades'}
                titleClass="font-normal"
              />
            </th>
            <th align="center">
              <SortableColumnHeader
                sortKey="market"
                sort={() => requestSort('market')}
                sortConfig={sortConfig}
                title={'OPEN INTEREST'}
                titleClass="font-normal"
              />
            </th>
            <th align="center">
              <SortableColumnHeader
                sortKey="market"
                sort={() => requestSort('market')}
                sortConfig={sortConfig}
                title={'Funding rate'}
                titleClass="font-normal"
              />
            </th>
            <th align="center">
              <SortableColumnHeader
                sortKey="market"
                sort={() => requestSort('market')}
                sortConfig={sortConfig}
                title={'available liquidity'}
                titleClass="font-normal"
              />
            </th>
            <th className="w-16 text-center text-xs font-normal">FAVS</th>
          </tr>
        </thead>
      </table>
      <div className="h-[calc(100%-40px)] overflow-y-scroll">
        {markets.length === 0 ? (
          <div className="flex h-full">
            <EmptyState
              text="No Market Available"
              textClasses="text-th-fgd-2"
            />
          </div>
        ) : (
          <table className="w-full text-left">
            <tbody>
              {uniqBy(markets, 'token.name').map((market, idx) => {
                const fundingRateLong = getFundingFactorPerPeriod(
                  market.marketsStats[0].marketInfo,
                  true,
                  CHART_PERIODS['1h'],
                ).mul(100)

                const fundingRateShort = getFundingFactorPerPeriod(
                  market.marketsStats[0].marketInfo,
                  false,
                  CHART_PERIODS['1h'],
                ).mul(100)

                return (
                  <tr
                    key={idx}
                    className="table h-12 w-full table-fixed cursor-pointer md:hover:bg-th-bkg-2"
                    onClick={() =>
                      push({
                        pathname: '/trade',
                        query: {
                          symbol: market.token.symbol,
                        },
                      })
                    }
                  >
                    <td className="pl-6">
                      <div className="flex items-center gap-2">
                        <div className="min-w-fit">
                          <RenderTokenIcon symbol={market.token.symbol} />
                        </div>
                        <p className="text-sm font-bold text-th-fgd-1">
                          {market.token.symbol}
                        </p>
                      </div>
                    </td>
                    <td
                      className="text-sm font-medium text-th-fgd-1"
                      align="left"
                    >
                      <LiveMarketPrice
                        quotePythId={market.token.pythId || ''}
                        basePythId={market.token.baseId}
                        tokenAddress={market.token.address}
                        textContentOnly={true}
                      />
                    </td>
                    <td align="left">
                      <MarketChange
                        deltaPercentage={
                          keyByPriceDeltas[market.token.symbol]?.deltaPercentage
                        }
                      />
                    </td>
                    <td align="left" className="text-th-fgd-1">
                      $
                      {getVolumeInfo(
                        market.marketsStats[0].marketInfo.marketTokenAddress.toLowerCase(),
                        keyBymarketsVolumeInfos,
                      )}
                    </td>
                    <td align="left">-</td>
                    <td>
                      <div className="flex items-center gap-2.5">
                        <div className="flex-1 text-end text-th-success">
                          {market &&
                            formatUsd(
                              market.marketsStats[0].marketInfo.longInterestUsd,
                              {
                                displayDecimals: 0,
                              },
                            )}
                        </div>
                        <div className="flex-1 text-start text-th-error">
                          {market &&
                            formatUsd(
                              market.marketsStats[0].marketInfo
                                .shortInterestUsd,
                              {
                                displayDecimals: 0,
                              },
                            )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center gap-2.5">
                        <span className="flex-1 text-end text-th-success">
                          {fundingRateLong.gt(0) ? '+' : '-'}
                          {formatAmount(fundingRateLong.abs(), 30, 4)}%
                        </span>
                        <span className="flex-1 text-start text-th-error">
                          {fundingRateShort.gt(0) ? '+' : '-'}
                          {formatAmount(fundingRateShort.abs(), 30, 4)}%
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center gap-2.5">
                        <span className="flex-1 text-end text-th-success">
                          {formatUsd(
                            getAvailableUsdLiquidityForPosition(
                              market.marketsStats[0].marketInfo,
                              true,
                            ),
                          )}
                        </span>
                        <span className="flex-1 text-start text-th-error">
                          {formatUsd(
                            getAvailableUsdLiquidityForPosition(
                              market.marketsStats[0].marketInfo,
                              false,
                            ),
                          )}
                        </span>
                      </div>
                    </td>
                    <td className="w-16">
                      <div
                        className="flex w-full justify-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <FavoriteMarketButton
                          market={market.token.symbol}
                          localStorageKey={FAVORITE_MARKET_KEY}
                          DefaultLocalStorageValue={DEFAULT_FAVORITE_MKTS}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

export default MarketsTable
