import clsx from 'clsx'

interface TabProps {
  label: string
  activeValue: string
  handleSelect: () => void
}

export const Tab = ({ activeValue, handleSelect, label }: TabProps) => {
  return (
    <div
      className={clsx(
        'text-sm font-semibold leading-[18px]',
        label === activeValue
          ? 'text-th-fgd-1'
          : 'cursor-pointer text-th-fgd-3',
      )}
      onClick={handleSelect}
    >
      {label}
    </div>
  )
}
