import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import Tooltip from '@components/shared/Tooltip'
import { Transition } from '@headlessui/react'
import { TokensData } from 'domain/synthetics/tokens'
import { TradeType } from 'domain/synthetics/trade/types'
import useLocalStorageState from 'hooks/useLocalStorageState'
import Link from 'next/link'
import StarIcon from 'public/icons/favourite-star.svg'
import { DEFAULT_FAVORITE_MKTS, FAVORITE_MARKETS_KEY } from 'utils/constants'

interface Props {
  id?: string
  tokensData?: TokensData
  setToTokenAddress?: (
    tokenAddress?: string,
    marketTokenAddress?: string,
    tradeType?: TradeType,
  ) => void
}

const FavoriteMarketsBar = ({ id, setToTokenAddress, tokensData }: Props) => {
  const [favoriteMarkets, setFavorites] = useLocalStorageState<string[]>(
    id || FAVORITE_MARKETS_KEY,
    DEFAULT_FAVORITE_MKTS,
  )

  const handleTokenAddress = (market: string) => {
    const token = tokensData
      ? Object.values(tokensData).find((item) => item.symbol === market)
      : null

    return token?.address
  }

  return (
    <Transition
      className="relative flex h-8 items-center space-x-3 border-b border-th-input-border bg-th-bkg-6 pl-4 sm:pl-6"
      show={!!favoriteMarkets.length}
      enter="transition-all ease-in duration-200"
      enterFrom="opacity-0 h-2"
      enterTo="opacity-100 h-10"
      leave="transition-all ease-out duration-200"
      leaveFrom="opacity-100 h-10"
      leaveTo="opacity-0 h-2"
    >
      <Tooltip content="Remove all favorites">
        <div
          onClick={() => setFavorites([])}
          className="min-w-fit cursor-pointer"
        >
          <StarIcon className="h-5 w-5 shrink-0" />
        </div>
      </Tooltip>

      <div className="flex items-center gap-x-6 overflow-x-auto whitespace-nowrap">
        {favoriteMarkets?.map((mkt: string) => {
          return (
            <Link
              className="flex items-center gap-2 whitespace-nowrap hover:text-th-active hover:opacity-100 focus-visible:text-th-fgd-1 focus-visible:opacity-100 focus-visible:outline-none"
              href={`/trade?name=${mkt}`}
              key={mkt}
              shallow={true}
              onClick={() => {
                const tokenAddress = handleTokenAddress(mkt)
                if (setToTokenAddress && tokenAddress) {
                  setToTokenAddress(tokenAddress)
                }
              }}
            >
              <RenderTokenIcon symbol={mkt} size="smallest" />

              <span className="text-sm font-semibold uppercase leading-[18px] text-th-fgd-1">
                {mkt}
                {!mkt.includes('/') && '/USD'}
              </span>
            </Link>
          )
        })}
      </div>
    </Transition>
  )
}

export default FavoriteMarketsBar
