import { TokenData } from '@components/trade/gmx/MarketHeader'
import { CHART_PERIODS } from '@components/trade/gmx/tradingview/lib/legacy'
import { BASIS_POINTS_DIVISOR } from '@components/trade/gmx/tradingview/lib/numbers'
import { getMidPrice } from '@components/trade/gmx/tradingview/utils'
import { getFundingFactorPerPeriod } from 'domain/synthetics/fees/utils'
import {
  getMaxReservedUsd,
  getReservedUsd,
  useMarketsInfo,
} from 'domain/synthetics/markets'
import { MarketInfo } from 'domain/synthetics/markets/types'
import { BigNumber } from 'ethers'
import { useChainId } from 'gmx/lib/chains'
import { useMemo } from 'react'
import { isMarketDisabled } from 'utils/markets'

export interface MarketStats {
  marketInfo: MarketInfo
  poolValueUsd: BigNumber
  fundingRateLong: BigNumber
  fundingRateShort: BigNumber
  utilization: BigNumber
}
export interface IndexTokenStats {
  token: TokenData
  price: BigNumber
  totalPoolValue: BigNumber
  avgFundingRateLong: BigNumber
  avgFundingRateShort: BigNumber
  totalUtilization: BigNumber
  totalReservedUsd: BigNumber
  totalMaxReservedUsd: BigNumber
  marketsStats: MarketStats[]
}

const useIndexTokenStats = (): IndexTokenStats[] => {
  const { chainId } = useChainId()
  const { marketsInfoData } = useMarketsInfo(chainId)

  const filteredMarketInfoData = useMemo(
    () =>
      Object.values(marketsInfoData ?? {}).filter(
        (marketInfo) =>
          marketInfo && !marketInfo.isSpotOnly && !isMarketDisabled(marketInfo),
      ),
    [marketsInfoData],
  )

  const indexTokensStats = useMemo(() => {
    const indexMap: Record<string, IndexTokenStats> = {}

    for (const marketInfo of filteredMarketInfoData) {
      const { indexToken, indexTokenAddress, poolValueMax } = marketInfo

      if (!indexMap[indexTokenAddress]) {
        indexMap[indexTokenAddress] = {
          token: indexToken,
          price: getMidPrice(indexToken.prices) ?? BigNumber.from(0),
          totalPoolValue: BigNumber.from(0),
          avgFundingRateLong: BigNumber.from(0),
          avgFundingRateShort: BigNumber.from(0),
          totalUtilization: BigNumber.from(0),
          totalReservedUsd: BigNumber.from(0),
          totalMaxReservedUsd: BigNumber.from(0),
          marketsStats: [],
        }
      }

      const indexTokenStats = indexMap[indexTokenAddress]

      const fundingRateLong = getFundingFactorPerPeriod(
        marketInfo,
        true,
        CHART_PERIODS['1h'],
      )
      const fundingRateShort = getFundingFactorPerPeriod(
        marketInfo,
        false,
        CHART_PERIODS['1h'],
      )

      const longReservedUsd = getReservedUsd(marketInfo, true)
      const shortReservedUsd = getReservedUsd(marketInfo, false)
      const maxLongReservedUsd = getMaxReservedUsd(marketInfo, true)
      const maxShortReservedUsd = getMaxReservedUsd(marketInfo, false)

      const totalReservedUsd = longReservedUsd.add(shortReservedUsd)
      const maxTotalReservedUsd = maxLongReservedUsd.add(maxShortReservedUsd)

      const utilization = maxTotalReservedUsd.gt(0)
        ? totalReservedUsd.mul(BASIS_POINTS_DIVISOR).div(maxTotalReservedUsd)
        : BigNumber.from(0)

      indexTokenStats.totalPoolValue =
        indexTokenStats.totalPoolValue.add(poolValueMax)
      indexTokenStats.totalReservedUsd =
        indexTokenStats.totalReservedUsd.add(totalReservedUsd)
      indexTokenStats.totalMaxReservedUsd =
        indexTokenStats.totalMaxReservedUsd.add(maxTotalReservedUsd)
      indexTokenStats.marketsStats.push({
        marketInfo,
        poolValueUsd: poolValueMax,
        fundingRateLong,
        fundingRateShort,
        utilization,
      })
    }

    for (const indexTokenStats of Object.values(indexMap)) {
      const marketsCount = indexTokenStats.marketsStats.length

      indexTokenStats.totalUtilization = indexTokenStats.totalMaxReservedUsd.gt(
        0,
      )
        ? indexTokenStats.totalReservedUsd
            .mul(BASIS_POINTS_DIVISOR)
            .div(indexTokenStats.totalMaxReservedUsd)
        : BigNumber.from(0)

      indexTokenStats.avgFundingRateLong = indexTokenStats.marketsStats
        .reduce((acc, stat) => acc.add(stat.fundingRateLong), BigNumber.from(0))
        .div(marketsCount)

      indexTokenStats.avgFundingRateShort = indexTokenStats.marketsStats
        .reduce(
          (acc, stat) => acc.add(stat.fundingRateShort),
          BigNumber.from(0),
        )
        .div(marketsCount)
    }

    return Object.values(indexMap).sort((a, b) =>
      b.totalPoolValue.gt(a.totalPoolValue) ? 1 : -1,
    )
  }, [filteredMarketInfoData])

  return indexTokensStats
}

export default useIndexTokenStats
