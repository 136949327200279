import { gql } from '@apollo/client'
import { IS_VERBOSE } from 'config/development'
import { getSyntheticsGraphClient } from 'gmx/lib/subgraph'
import useSWR from 'swr'

export type MarketVolumeInfo = {
  volumeUsd: string
  timestamp: number
  period: string
  id: string
  marketToken: string
}

export const useMarketInfoVolume = (chainId: number) => {
  const client = getSyntheticsGraphClient(chainId)

  const query = gql(`
    query {
      marketVolumeInfos(where: {period: "1d", marketToken_not: "total"} orderBy: timestamp orderDirection: desc){
      volumeUsd
      timestamp
      period
      id
      marketToken
    }
  }
  `)

  const { data }: { data: MarketVolumeInfo[] | undefined } = useSWR(
    'useMarketInfoVolume',
    {
      fetcher: async () => {
        if (!client) {
          return []
        }

        try {
          const { data }: { data: { marketVolumeInfos: MarketVolumeInfo[] } } =
            await client.query({
              query,
              fetchPolicy: 'no-cache',
            })

          return data?.marketVolumeInfos
        } catch (err) {
          // eslint-disable-next-line no-console
          IS_VERBOSE && console.log('🚀 ~ fetcher: ~ err:', err)
          return []
        }
      },
    },
  )

  return { marketsVolumeInfos: data || [] }
}
