import TokenFilters from '@components/shared/TokenFilters'
import FavoriteMarketsBar from '@components/trade/FavoriteMarketsBar'
import { Tab as MarketTab } from 'components/Common/Tab'
import SearchInput from 'components/SearchInput/SearchInput'
import useIndexTokenStats, {
  IndexTokenStats,
} from 'hooks/gmx/useIndexTokenStats'
import { useCallback, useMemo, useState } from 'react'
import { FAVORITE_MARKET_KEY } from 'utils/constants'
import MarketsTable from './MarketsTable'

const initilaFilterData: Record<string, { label: string; count: number }> = {
  ALL: { label: 'ALL', count: 0 },
  LABS: { label: 'LABS', count: 0 },
  BTC: { label: 'BTC', count: 0 },
  ETH: { label: 'ETH', count: 0 },
  CRYPTO: { label: 'CRYPTO', count: 0 },
  L1L2: { label: 'L1 / L2', count: 0 },
  MEMES: { label: 'MEMES', count: 0 },
  AMM: { label: 'AMM', count: 0 },
  DEFI: { label: 'DEFI', count: 0 },
}

export enum Tab {
  AllMarket = 'All Markets',
  Favourites = 'Favourites',
  NewlyListed = 'Newly Listed',
}

const tabs = [Tab.AllMarket, Tab.Favourites, Tab.NewlyListed]

const MarketsPage = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [activeTab, setActiveTab] = useState<Tab>(Tab.AllMarket)
  const [appliedFilter, setAppliedFilter] = useState(
    initilaFilterData.ALL.label,
  )

  const data = useIndexTokenStats()

  const filterMarkets = useCallback(
    (item: IndexTokenStats, criteria: string) => {
      const nameIncludes = (str: string) =>
        item.token.name.toLowerCase().includes(str)
      const symbolIncludes = (str: string) =>
        item.token.symbol.toLowerCase().includes(str)
      const indexTokenTag = item.marketsStats[0].marketInfo.indexToken.tag

      switch (criteria) {
        case 'ALL':
        case 'CRYPTO':
          return true
        case 'LABS':
          return false
        case 'BTC':
          return nameIncludes('btc') || symbolIncludes('btc')
        case 'ETH':
          return nameIncludes('eth') || symbolIncludes('eth')
        case 'L1L2':
          return ['L1', 'L2'].includes(indexTokenTag || '')
        case 'MEMES':
          return indexTokenTag === 'MEMES'
        case 'AMM':
          return indexTokenTag === 'AMM'
        case 'DEFI':
          return indexTokenTag === 'DEFI'
        default:
          return true
      }
    },
    [],
  )

  const filterBySearchQuery = (data: IndexTokenStats[], query: string) => {
    const lowerCaseQuery = query.toLowerCase().trim()
    return lowerCaseQuery !== ''
      ? data.filter(
          (item) =>
            item.token.name.toLowerCase().includes(lowerCaseQuery) ||
            item.token.symbol.toLowerCase().includes(lowerCaseQuery),
        )
      : data
  }

  const filteredMarkets = useMemo(() => {
    let filteredData = data

    if (filteredData) {
      filteredData = filteredData.filter((item) =>
        filterMarkets(item, appliedFilter),
      )
      filteredData = filterBySearchQuery(filteredData, searchQuery)
    }

    return filteredData
  }, [data, appliedFilter, searchQuery, filterMarkets])

  const countAfterFiltering = useCallback(
    (data: IndexTokenStats[], criteria: string) => {
      switch (criteria) {
        case 'ALL':
        case 'CRYPTO':
          return data.length
        case 'LABS':
          return 0
        case 'BTC':
        case 'ETH':
          return data.filter((item) => filterMarkets(item, criteria)).length
        default:
          return 0
      }
    },
    [filterMarkets],
  )

  const filteredMarketsCount = useMemo(() => {
    const updatedFilters = { ...initilaFilterData }

    Object.keys(updatedFilters).forEach((key) => {
      updatedFilters[key].count = countAfterFiltering(data, key)
    })

    return Object.keys(updatedFilters).map((key) => ({
      label: updatedFilters[key].label,
      count: updatedFilters[key].count,
    }))
  }, [data, countAfterFiltering])

  const handleMarketFilterChange = useCallback((val: string) => {
    setAppliedFilter(val)
  }, [])

  return (
    <div className="h-full">
      <FavoriteMarketsBar id={FAVORITE_MARKET_KEY} />
      <SearchInput
        placeholder="Search Market"
        className="h-10 flex-1 rounded-none border-none bg-transparent pl-6"
        inputBoxClasses="text-sm h-10 w-full"
        value={searchQuery}
        setValue={(event) => setSearchQuery(event.target.value.trim())}
      />

      <div className="flex h-12 items-center border-y border-th-input-border px-4 sm:px-6">
        <TokenFilters
          appliedFilter={appliedFilter}
          changeFilter={handleMarketFilterChange}
          options={filteredMarketsCount}
        />
      </div>

      <div className="flex h-10 items-center gap-4 border-b border-th-input-border px-4 sm:h-12 sm:gap-6 sm:px-6">
        {tabs.map((tab) => (
          <MarketTab
            key={tab}
            label={tab}
            activeValue={activeTab}
            handleSelect={() => setActiveTab(tab)}
          />
        ))}
      </div>

      <div className="sm:h-[calc(100%-172px)]">
        <MarketsTable data={filteredMarkets} tab={activeTab} />
      </div>
    </div>
  )
}

export default MarketsPage
